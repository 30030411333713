import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Loader } from '@calm-web/design-system';

import InitialPageAnalytics from '@/components/analytics/InitialPageAnalytics';
import AchPendingModal from '@/components/ui/ACHPendingModal';
import { useActionPlans } from '@/hooks/api/useActionPlan';
import { useActivateAchPaymentMethodLifeCycleManagement } from '@/hooks/api/useActivateAchPaymentMethodLifeCycleManagement';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import { isCalmHealthProductSKU } from '@/utils/SkuUtils';

import ActionPlanSection from './ActionPlan';
import GetSetupSection from './GetSetup';
import { LaunchAnnouncementModal } from './LaunchAnnouncementModal';
import SignupReminderModal from './SignupReminderModal';
import Stats from './Stats';
import { Wrapper } from './styles';

const AchModalKey = {
	VERIFYING_OR_PROCESSING: 'did-show-ach-payment-method-verify-or-processing-modal',
	PROCESSED: 'did-show-ach-payment-method-processed-modal',
} as const;

type AchModalKey = (typeof AchModalKey)[keyof typeof AchModalKey];

export default function Home(): ReturnType<FC> {
	const { partnerId } = useParams();
	const { data, loading } = useActionPlans(partnerId);
	const partnerData = useDefinedPartner();
	const history = useHistory();
	const { search } = useLocation();
	const [showAchModal, setShowAchModal] = useState(false);

	const {
		isLoading: isLoadingAchStatus,
		ui: { modal: modalInfo },
		achModalKey,
	} = useActivateAchPaymentMethodLifeCycleManagement();

	useEffect(() => {
		if (!isLoadingAchStatus && achModalKey) {
			const storageKey =
				achModalKey === 'PROCESSED' ? AchModalKey.PROCESSED : AchModalKey.VERIFYING_OR_PROCESSING;

			if (storageKey === AchModalKey.VERIFYING_OR_PROCESSING) {
				localStorage.removeItem(AchModalKey.PROCESSED);
			}

			if (!localStorage.getItem(storageKey)) {
				localStorage.setItem(storageKey, 'true');
				setShowAchModal(true);
			}
		}
	}, [isLoadingAchStatus, achModalKey]);

	const handleModalClose = (): void => {
		setShowAchModal(false);
	};

	if (loading || isLoadingAchStatus) {
		return <Loader color="gray1" />;
	}

	if (isCalmHealthProductSKU(partnerData?.product_sku)) {
		history.push({ pathname: `/${partnerId}/account`, search });
	}

	const handleDisplayAchModal = showAchModal && achModalKey !== null;

	return (
		<Wrapper>
			<InitialPageAnalytics />
			{handleDisplayAchModal && modalInfo.title && modalInfo.bodyText && (
				<AchPendingModal
					title={modalInfo.title}
					bodyText={modalInfo.bodyText}
					buttonText={modalInfo.buttonText}
					onPress={handleModalClose}
					showConfetti={modalInfo.showConfetti}
				/>
			)}
			<Stats />
			{data?.getSetupComplete ? (
				<ActionPlanSection actionPlanStatuses={data?.actionPlanStatuses} />
			) : (
				<GetSetupSection getSetupStatuses={data?.getSetupStatuses} />
			)}

			<LaunchAnnouncementModal />
			<SignupReminderModal />
		</Wrapper>
	);
}
