import styled from 'styled-components';
import { palette } from 'styled-tools';

import { FontSizes, FontWeights, maxWidth, Text, TextAlign, units } from '@calm-web/design-system';

export const Title = styled(Text).attrs({
	size: FontSizes.xl,
	align: TextAlign.Center,
})`
	word-break: break-word;
`;

export const TimeText = styled(Text).attrs({
	size: FontSizes.sm,
})`
	padding-left: ${units(2)};
	padding-right: ${units(2)};
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;

	${maxWidth('tablet')} {
		flex-direction: column;
		align-items: center;
	}
`;

export const TableWrapper = styled.div`
	margin-top: ${units(2)};
	width: 100%;
	background: ${palette('white')};
	border-radius: ${units(2)};
	padding: ${units(2)} 0;
`;

export const HeaderTable = styled.div`
	width: 600px;
	padding-bottom: ${units(2)};

	${maxWidth('tablet')} {
		width: 360px;
	}
`;

export const LogTableText = styled(Text).attrs({
	size: FontSizes['2xl'],
	weight: FontWeights.Demi,
	noMargin: true,
})`
	display: flex;
	text-align: left;
`;
