import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { setBannerMessage } from '@/store/actions';
import { isSelfServePlan } from '@/utils/SkuUtils';
import { calmLogger } from '@/utils/calmLogger';

import messages from './messages';
import { BannerLink, BannerTextButton } from './styles';
import { useNavToPlanPageAndOpenUpdatePaymentDetailsModal } from './useNavToPlanPageAndOpenUpdatePaymentDetailsModal';
import { useDefinedPartner } from './usePartner';
import { usePurchase } from './usePurchase';

type AchModalKey = 'VERIFYING_OR_PROCESSING' | 'PROCESSED' | null;

interface UseActivateAchPaymentMethodLifeCycleManagement {
	isLoading: boolean;
	ui: {
		paymentDetails: {
			error: string | undefined;
		};
		banner: {
			error: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			pendingCopy: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
		};
		modal: {
			title: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			bodyText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			buttonText: ReturnType<ReturnType<typeof useIntl>['formatMessage']> | undefined;
			showConfetti: boolean;
		};
	};
	achModalKey: AchModalKey;
}

export const useActivateAchPaymentMethodLifeCycleManagement =
	(): UseActivateAchPaymentMethodLifeCycleManagement => {
		const { formatMessage } = useIntl();
		const { getPaymentInfo } = usePurchase();
		const [bannerError, setAchPaymentMethodActivationBannerError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [error, setAchPaymentMethodActivationError] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['paymentDetails']['error']>();
		const [pendingCopy, setPendingCopy] =
			useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['banner']['pendingCopy']>();
		const [isLoading, setIsLoading] = useState<boolean>(true);
		const [modalInfo, setModalInfo] = useState<UseActivateAchPaymentMethodLifeCycleManagement['ui']['modal']>(
			{
				title: undefined,
				bodyText: undefined,
				buttonText: formatMessage(messages.achButtonText),
				showConfetti: false,
			},
		);
		const [achModalKey, setAchModalKey] = useState<AchModalKey>(null);
		const dispatch = useDispatch();
		const partner = useDefinedPartner();
		const [
			shouldGetActivateAchPaymentMethodLifeCycleInfo,
			setShouldGetActivateAchPaymentMethodLifeCycleInfo,
		] = useState(true);
		const navToPlanPageAndOpenUpdatePaymentDetailsModal =
			useNavToPlanPageAndOpenUpdatePaymentDetailsModal(false);

		useEffect(() => {
			const getActivateAchPaymentMethodLifeCycleInfo = async (): Promise<void> => {
				try {
					if (!isSelfServePlan(partner.vouched_plan_sku)) {
						return;
					}
					setShouldGetActivateAchPaymentMethodLifeCycleInfo(false);
					setIsLoading(true);
					setAchPaymentMethodActivationError(undefined);
					setPendingCopy(undefined);
					setAchModalKey(null);

					const paymentInfo = await getPaymentInfo();
					if (paymentInfo.type !== 'us_bank_account') {
						return;
					}

					const intentStatus = paymentInfo.intent_status;
					const errorReason = paymentInfo.last_intent_error;

					if (intentStatus === 'requires_payment_method' && errorReason) {
						if (
							errorReason === 'bank_account_verification_failed' ||
							errorReason.startsWith('payment_method_microdeposit')
						) {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodVerificationFailedBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodVerificationFailedError),
							);
						} else {
							setAchPaymentMethodActivationBannerError(
								formatMessage(messages.achPaymentMethodProcessingFailedBannerError, {
									changedetails: (...chunks: React.ReactNode[]) => (
										<BannerTextButton onClick={navToPlanPageAndOpenUpdatePaymentDetailsModal}>
											{chunks}
										</BannerTextButton>
									),
								}) as string,
							);
							setAchPaymentMethodActivationError(
								formatMessage(messages.achPaymentMethodProcessingFailedError),
							);
						}
						setAchModalKey(null);
					} else if (intentStatus === 'processing') {
						setPendingCopy(formatMessage(messages.achPaymentMethodProcessing));
						setModalInfo({
							title: formatMessage(messages.achProcessingTitle),
							bodyText: formatMessage(messages.achProcessingBody, {
								newline: () => <br />,
								b: chunks => <strong>{chunks}</strong>,
							}),
							buttonText: formatMessage(messages.achButtonText),
							showConfetti: false,
						});
						setAchModalKey('VERIFYING_OR_PROCESSING');
					} else if (intentStatus === 'requires_action') {
						setModalInfo({
							title: formatMessage(messages.achVerifyingTitle),
							bodyText: formatMessage(messages.achVerifyingBody, {
								newline: () => <br />,
								b: chunks => <strong>{chunks}</strong>,
							}),
							buttonText: formatMessage(messages.achButtonText),
							showConfetti: false,
						});
						setAchModalKey('VERIFYING_OR_PROCESSING');

						if (paymentInfo.next_action?.verify_with_microdeposits === undefined) {
							calmLogger.warn(
								'ACH payment method verifying, but no microdeposit next_actions on payment info',
								{
									partnerId: partner.id,
								},
							);

							return;
						}
						setPendingCopy(
							formatMessage(messages.achPaymentMethodVerifying, {
								arrivalDate: new Date(
									paymentInfo.next_action?.verify_with_microdeposits?.arrival_date * 1000,
								).toLocaleDateString('en-US', {
									weekday: 'long',
									month: 'long',
									day: 'numeric',
									year: 'numeric',
								}),
								link: (...chunks: React.ReactNode[]) => {
									return (
										<BannerLink
											href={paymentInfo.next_action?.verify_with_microdeposits?.hosted_verification_url}
										>
											{chunks}
										</BannerLink>
									);
								},
							}),
						);
					} else if (intentStatus === 'succeeded') {
						setModalInfo({
							title: formatMessage(messages.achSucceededTitle),
							bodyText: formatMessage(messages.achSucceededBody, {
								newline: () => <br />,
								b: chunks => <strong>{chunks}</strong>,
							}),
							buttonText: formatMessage(messages.achSucceededButtonText),
							showConfetti: true,
						});
						setAchModalKey('PROCESSED');
					}
				} catch (err) {
					dispatch(
						setBannerMessage({
							message: formatMessage(messages.getPaymentInfoError),
							isError: true,
							flash: true,
						}),
					);
					throw err;
				} finally {
					setIsLoading(false);
				}
			};

			if (shouldGetActivateAchPaymentMethodLifeCycleInfo) {
				getActivateAchPaymentMethodLifeCycleInfo().catch(err =>
					calmLogger.error(
						'An error occurred in getActivateAchPaymentMethodLifeCycleInfo',
						{
							partnerId: partner.id,
						},
						err,
					),
				);
			}
		}, [
			getPaymentInfo,
			dispatch,
			formatMessage,
			shouldGetActivateAchPaymentMethodLifeCycleInfo,
			partner,
			navToPlanPageAndOpenUpdatePaymentDetailsModal,
		]);

		return {
			isLoading,
			ui: {
				paymentDetails: {
					error,
				},
				banner: {
					error: bannerError,
					pendingCopy,
				},
				modal: modalInfo,
			},
			achModalKey,
		};
	};
