import React, { ReactNode, useRef } from 'react';

import { PrimaryButton, Modal } from '@calm-web/design-system';

import Confetti from '../Confetti';
import { TitleText, Paragraph, FooterContainer } from './styles';

interface AchPendingModalProps {
	title: ReactNode | ReactNode[];
	bodyText: ReactNode | ReactNode[];
	buttonText: ReactNode | ReactNode[];
	onPress: () => void;
	showConfetti?: boolean;
}

const AchPendingModal: React.FC<AchPendingModalProps> = ({
	title,
	bodyText,
	buttonText,
	onPress,
	showConfetti = false,
}) => {
	const confettiRef = useRef<HTMLDivElement>(null);

	return (
		<Modal
			isOpen={true}
			closeModal={onPress}
			canClose={false}
			titleElement={<TitleText>{title}</TitleText>}
			aria-labelledby="ach-pending-modal"
			width="Extra"
			additionalOverlay={showConfetti ? <Confetti bgSize="90" ref={confettiRef} /> : undefined}
		>
			<Paragraph>{bodyText}</Paragraph>
			<FooterContainer>
				<PrimaryButton onPress={onPress}>{buttonText}</PrimaryButton>
			</FooterContainer>
		</Modal>
	);
};

export default AchPendingModal;
